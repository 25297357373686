<template>
  <div class="body">
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
      style="margin-bottom: 10px"
    />
    <div class="infoBox" v-for="(item, index) in infoData" :key="index">
      <div class="formItem">
        <div class="label">红利年度：</div>
        <van-field
          style="font-size: 16px; padding: 5px 10px"
          :value="item.year"
          readonly
        />
      </div>
      <div class="formItem">
        <div class="label">姓名：</div>
        <van-field
          style="font-size: 16px; padding: 5px 10px"
          :value="item.name"
          readonly
        />
      </div>
      <div class="formItem">
        <div class="label">项目类别：</div>
        <van-field
          style="font-size: 16px; padding: 5px 10px"
          :value="
            common.gettypeListLabelFn(
              'projectCategoryList',
              item.projectCategory
            )
          "
          readonly
        />
      </div>
      <div class="formItem">
        <div class="label">项目名称：</div>
        <van-field
          style="font-size: 16px; padding: 5px 10px"
          :value="
            common.gettypeListLabelFn('projectNameList', item.projectName)
          "
          readonly
        />
      </div>
      <div class="formItem">
        <div class="label">金额：</div>
        <van-field
          style="font-size: 16px; padding: 5px 10px"
          :value="item.amountMoney"
          readonly
        />
      </div>
      <div class="formItem">
        <div class="label">项目内容：</div>
        <van-field
          style="
            font-size: 16px;
            padding: 5px 10px;
            background-color: #f2f2f2;
            border-radius: 10px;
          "
          :value="item.projectContent"
          type="textarea"
          autosize
          readonly
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      infoData: [],
      title: "",
    };
  },
  created() {
    this.infoData = this.$route.query.infoData;
    this.title = this.$route.query.type.typeName;
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.body {
  background-color: #f6f6f6;
  min-height: 100vh;
}
.formItem {
  display: flex;
  justify-content: center;
  font-size: 16px;
}
.label {
  width: 2rem;
  padding: 5px 10px;
}
.infoBox {
  margin: 0 auto 10px;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  background-color: #fff;
}
</style>